<div class="p-2 font-bold ism-gold text-1.5">Fleet Orders</div>

<div class="my-2 px-2 w-full flex items-center">
  <div class="text-1.5">Star Systems with Fleets</div>
  <div class="ml-2">
    <input class="ml-8 text-1.25 form-checkbox" type="checkbox" [(ngModel)]="showHistory" />
    Show History
  </div>
</div>

<cdk-accordion class="">
  @for (informationStarSystem of informationStarSystems() | orderBy: "starSystemNumber"; track $index) {
    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="my-2" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Star System With Fleets - {{informationStarSystem.starSystemNumber}}'">
      <!-- @let starSystemId = informationStarSystem.starSystemId -->
      <!-- Summary -->
      <div class="p-2 border-1 w-full flex items-center" (click)="accordionItem.toggle()" [attr.id]="'header-' + $index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'body-' + $index" role="button">
        <div class="tile-title">StarSystem Number: {{ informationStarSystem.starSystemNumber }}</div>
        <div class="p-2">{{ fleetsByStarSystemId[informationStarSystem.starSystemId]?.length || 0 }} Fleets</div>
      </div>

      <!-- Detail -->
      <div role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'body-' + $index" [attr.aria-labelledby]="'header-' + $index">
        @for (fleet of fleetsByStarSystemId[informationStarSystem.starSystemId] | orderBy: "fleetName"; track $index) {
          <div class="mx-1 flex justify-between items-center border-1 border-yellow-400">
            <div class="p-2 whitespace-nowrap ism-gold">{{ fleet.fleetName }} in {{ informationStarSystem.starSystemNumber }} at {{ fleet.locationHex }}</div>
            <!-- -->
            <div class="flex items-center">
              @if (fleet.reserved) {
                <div class="mx-2">{{ fleet.mode }}</div>
              }
              <div class="w-full mx-2 text-right">{{ fleet.ships.length }} ships</div>
            </div>
          </div>

          @for (fleetOrder of fleetOrdersByFleetId()[fleet._id]; track $index) {
            <div class="pl-4 w-full flex flex-col">
              <div class="flex items-center justify-between">
                <fleet-order [fleetOrder]="fleetOrder"></fleet-order>
                <div class="flex items-center justify-end">
                  @if ($last) {
                    <button id="_ADD_" type="button" data-tooltip="Add Order" class="mx-2 flex items-center tooltip-tl" (click)="add(fleet)">
                      <!-- [disabled]="newDisabled(fleet._id)" -->
                      <ism-svg [name]="'plus'" class-string="'w-8 h-8 flex items-center hover:text-white ism-gold'"></ism-svg>
                    </button>
                  }
                  <!-- -->
                  @if ($first && fleetOrder.complete === 0) {
                    <button id="_STOP_" type="button" data-tooltip="Stop/Interrupt Order" class="p-2 text-white visited:text-white tooltip-tl hover:ism-gold" (click)="interrupt(fleet._id)">
                      <!-- [disabled]="!lastOrderActive(fleet._id)" -->
                      <ism-svg [name]="'svgNoSymbol'" class-string="'w-8 h-8 flex items-center hover:text-white ism-gold'"></ism-svg>
                    </button>
                  }
                  <!-- -->
                  @if ($last && fleetOrder.complete === 0 && (fleetOrder?.elapsed || 0) === 0) {
                    <button id="_DELETE_" type="button" data-tooltip="Delete Order" class="mx-2 flex items-center tooltip-tl" (click)="delete(fleet._id)">
                      <!-- [disabled]="!lastOrderActive(fleet._id)" -->
                      <ism-svg [name]="'xSquare'" class-string="'w-8 h-8 flex items-center hover:text-white ism-gold'"></ism-svg>
                    </button>
                  }
                </div>
              </div>
            </div>
          }
        }
      </div>
    </cdk-accordion-item>
  }
</cdk-accordion>

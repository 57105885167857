import { Component, computed, effect, inject, Injector, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, Observable, tap } from 'rxjs';
import { sortBy } from 'lodash';

import { FleetOrderEditComponent } from '../fleet-order-edit/fleet-order-edit.component';

import { FleetOrderService } from '../../services/fleet-order.service';
import { FleetService } from '../../services/fleet.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';
import { InformationWarpPointService } from '../../services/information-warp-point.service';
import { SessionService } from '../../services/session.service';
import { ShipService } from '../../services/ship.service';
import { StarSystemService } from '../../services/star-system.service';
import { TableService } from '../../services/table.service';

import { Fleet } from '../../interfaces/fleet';
import { Race } from '../../interfaces/race';
import { FleetOrder } from '../../interfaces/fleet-order';
import { InformationStarSystem } from '../../interfaces/information-star-system';
import { InformationWarpPoint } from '../../interfaces/information-warp-point';
import { UtilityService } from '../../services/utility.service';
import { StarSystem } from '../../interfaces/star-system';
import { DialogData } from '../../interfaces/dialog-data';
import { Dialog } from '@angular/cdk/dialog';
import { FleetInstruction } from '../../interfaces/fleet-instruction';

interface FleetOrderContext {
  starSystemId: string,
  locationHex: string,
  atWP: boolean,
  hasXXr: boolean,
  canDoRough: boolean,
  canDoDetail: boolean,
};

@Component({
  selector: 'fleet-orders',
  templateUrl: './fleet-orders.component.html',
})
export class FleetOrdersComponent implements OnInit {
  injector = inject(Injector);
  injectorOption = { injector: this.injector };
  fleetOrderService = inject(FleetOrderService);
  fleetService = inject(FleetService);
  informationStarSystemService = inject(InformationStarSystemService);
  informationWarpPointService = inject(InformationWarpPointService);
  session = inject(SessionService);
  shipService = inject(ShipService);
  starSystemService = inject(StarSystemService);
  tableService = inject(TableService);
  utilityService = inject(UtilityService);
  dialog = inject(Dialog);

  race: Race = this.session.getRace();
  showHistory = signal<boolean>(false);
  informationStarSystemById: { [key: string]: InformationStarSystem; } = {};

  fleetInstructions: Signal<FleetInstruction[]>;
  fleetInstructionsByCode = computed(() => {
    return this.fleetInstructions().reduce((hash, instruction) =>
      (hash[instruction.code] = instruction, hash),
      {} as { [key: string]: any; }
    );
  });

  informationStarSystems = toSignal(
    this.informationStarSystemService.getInformationStarSystemForRaceId(this.race._id).pipe(
      tap(informationStarSystems =>
        this.informationStarSystemById = informationStarSystems.reduce(
          (hash, iss) => (hash[iss.starSystemId] = iss, hash), {}
        )
      )
    )
  );

  starSystemById: { [key: string]: StarSystem; } = {};
  fleetsById: { [key: string]: Fleet; } = {};
  fleetsByStarSystemId: { [key: string]: Fleet[]; } = {};
  fleetOrders: WritableSignal<FleetOrder[]> = signal([]);
  fleetOrdersByFleetId: Signal<{ [key: string]: FleetOrder[]; }> = computed(() => {
    let fleetIds = new Set<string>();
    let ordersForId: { [key: string]: FleetOrder[]; } = this.fleetOrders().reduce(
      (hash, order) => {
        let orders = hash[order.fleetId] || [];
        orders.push(order);
        hash[order.fleetId] = orders;
        fleetIds.add(order.fleetId);
        return hash;
      }, {}
    );

    for (const fleetId of fleetIds) {
      let fleet: Fleet;
      try {
        fleet = this.fleetsById[fleetId];
        if (fleet) {
          let orders = sortBy(ordersForId[fleetId], 'sequence');
          ordersForId[fleetId] = orders;
          this.buildContext(fleet, orders);
        }
      }
      catch (error) {
        console.log('fleetId + error:', fleetId, error);
      }
    }
    return ordersForId;
  });

  informationWarpPointsById: { [key: string]: InformationWarpPoint; } = {};
  informationWarpPointsByStarSystemId: { [key: string]: InformationWarpPoint[]; } = {};
  contextualFleets: Fleet[];
  fleets: Signal<Fleet[]> = signal([]);

  constructor () {
    this.fleetInstructions = toSignal<any[]>(this.tableService.getBaseTableType('instructions'));
  };

  ngOnInit (): void {
    this.race = this.session.getRace();

    let starSystems$ = this.starSystemService.getStarSystemsForRaceId(this.race._id).pipe(
      tap(starSystems => {
        this.starSystemById = starSystems.reduce(
          (hash, starSystem) => (hash[starSystem._id] = starSystem, hash), {}
        );
      })
    ).subscribe();

    this.fleets = toSignal(this.fleetService.getActiveFleetsForRace$(this.race._id).pipe(
      map(fleets => {
        // console.log('fleets.length: ' + fleets.length);
        let systemSet = new Set();
        this.fleetsByStarSystemId = {};
        this.fleetsById = {};
        for (var i = 0; i < fleets.length; i++) {
          var fleet = fleets[i];
          fleet.reserved = fleet.mode === 'Diplomat';
          fleet.hasXXr = false;
          fleet.XXr = 0;
          fleet.XOnly = 0;
          fleet.speedMax = 1;
          for (const ship of fleet.ships) {
            fleet.speedMax = Math.max((ship?.speedMaxStrategic || fleet.speedMax), fleet.speedMax);
            let itemCounts = this.shipService.countItems(ship, ['X', 'Xr']);
            ship.hasXXr = (itemCounts.X > 0 && itemCounts.Xr > 0);
            if (ship.XXr) {
              fleet.XXr += 1;
            }
            else if (itemCounts.X > 0) {
              fleet.XOnly += 1;
            }
            fleet.hasXXr = fleet.hasXXr || ship.hasXXr;
          }
          this.fleetsById[fleet._id] = fleet;
          let ssid = fleet.starSystemId;
          if (!systemSet.has(ssid)) {
            this.fleetsByStarSystemId[ssid] = [];
            systemSet.add(ssid);
          }
          this.fleetsByStarSystemId[ssid].push(fleet);
        }
        return fleets;
      })
    ), this.injectorOption);

    let informationWarpPoints$ = this.informationWarpPointService.getLeanInformationWarpPointsForRaceId(this.race._id).pipe(
      map(informationWarpPoints => {
        this.informationWarpPointsById = (informationWarpPoints || []).reduce(
          function (hash, informationWarpPoint) {
            if (informationWarpPoint.warpPoint) {
              informationWarpPoint.strategicHex = informationWarpPoint.warpPoint.strategicHex;
              if (informationWarpPoint.turnExplored > 0) {
                informationWarpPoint.destinationSystemNumber = informationWarpPoint.warpPoint.destination;
                informationWarpPoint.destinationHex = informationWarpPoint.warpPoint.destinationStrategicHex;
                informationWarpPoint.destinationStarSystemId = informationWarpPoint.warpPoint.destinationStarSystem;
              }
              delete informationWarpPoint.warpPoint;
            }

            let iwpsForSsid = this.informationWarpPointsByStarSystemId[informationWarpPoint.starSystemId] || [];
            iwpsForSsid.push(informationWarpPoint);
            this.informationWarpPointsByStarSystemId[informationWarpPoint.starSystemId] = iwpsForSsid;

            hash[informationWarpPoint.starSystemId + informationWarpPoint.strategicHex] = informationWarpPoint;
            hash[informationWarpPoint._id] = informationWarpPoint;
            return hash;
          }, {}
        );
      })
    ).subscribe();

    effect(() => {
      let getOrders$: Observable<FleetOrder[]>;
      if (this.showHistory()) {
        getOrders$ = this.fleetOrderService.getAllFleetOrdersForRaceId(this.race._id);
      }
      else {
        getOrders$ = this.fleetOrderService.getFleetOrdersForRaceId(this.race._id);
      }
      getOrders$.subscribe(
        orders => this.fleetOrders.set(orders)
      );
    }, this.injectorOption);
  };

  getInformationWarpPointHashForStarSystemID (starSystemId) {
    let informationWarpPoints: InformationWarpPoint[] = this.informationWarpPointsByStarSystemId[starSystemId] || [];
    let iwpHash = informationWarpPoints.reduce((hash, informationWarpPoint) => {
      hash[informationWarpPoint.strategicHex] = informationWarpPoint;
      return hash;
    }, {});
    return iwpHash;
  };

  buildContext (fleet: Fleet, fleetOrders: FleetOrder[]): FleetOrderContext {
    let iwpHash = this.getInformationWarpPointHashForStarSystemID(fleet.starSystemId);
    let iwp = iwpHash[fleet.locationHex];
    let starSystemInfo = this.informationStarSystemById[fleet.starSystemId];

    let canDoRough = starSystemInfo.surveyWarpPass1 <= 200;
    let canDoDetail = starSystemInfo.surveyWarpPass2 <= 200 && !canDoRough;

    let context: FleetOrderContext = {
      starSystemId: fleet.starSystemId,
      locationHex: fleet.locationHex,
      atWP: !!(iwp),
      hasXXr: !!(fleet.hasXXr),
      canDoRough: canDoRough,
      canDoDetail: canDoDetail
    };

    for (const order of fleetOrders) {
      let fleetId: string = order.fleetId;
      let fleet: Fleet = this.fleetsById[fleetId];
      let fleetInstruction: FleetInstruction = this.fleetInstructionsByCode()[order.code];
      let targetFleet: Fleet = this.fleetsById[order.element];
      let informationWarpPoint: InformationWarpPoint = this.informationWarpPointsById[order.element];
      this.fleetOrderService.buildDisplay(order, fleet, fleetInstruction, targetFleet, informationWarpPoint);

      if (order.complete > 0) {
        continue;
      }

      let pointsNeeded = 50;
      let pointsEarned = 0;
      let pointsPerStmp = 0;
      // instruction.estimate = this.utilityService.timeToTravel(context.locationHex, instruction.hex, instruction.speed) + 2;

      switch (order.code) {
        case 101:
          order.estimate = this.utilityService.timeToTravel(context.locationHex, order.hex, order.speed) + 2;
          context.locationHex = order.hex;
          iwp = iwpHash[order.hex];
          context.atWP = !!(iwp);
          break;
        case 102:
          order.estimate = this.utilityService.timeToTravel(context.locationHex, order.hex, order.speed) + 2;
          context.locationHex = order.hex;
          context.atWP = false;
          break;
        case 103:
          order.estimate = this.utilityService.timeToTravel(context.locationHex, order.hex, order.speed) + 2;
          context.locationHex = order.hex;
          context.atWP = true;
          break;
        case 104:
          order.estimate = this.utilityService.timeToTravel(context.locationHex, order.hex, order.speed) + 2;
          context.locationHex = order.hex;
          context.atWP = false;
          break;
        case 111:
          let targetFleet = this.fleetsById[order.element];
          if (targetFleet) {
            order.estimate = this.utilityService.timeToTravel(context.locationHex, targetFleet.locationHex, order.speed) + 2;
            context.locationHex = targetFleet.locationHex;
            iwp = iwpHash[context.locationHex];
          }
          context.atWP = !!(iwp);
          break;
        case 201:
          order.estimate = 2;
          let swp1 = 0;
          let swp2 = 0;
          let informationWP = this.informationWarpPointsById[context.starSystemId + context.locationHex];
          // is the ship on an actual warp point and destination is known?
          if (informationWP && informationWP.turnExplored > 0
            && informationWP.strategicHex === context.locationHex
            && informationWP.destinationStarSystemId) {
            // where does it lead, starSystemId-wise?
            // what hex is the destination WP in?
            context.starSystemId = informationWP.destinationStarSystemId;
            context.locationHex = informationWP.destinationHex;
            iwpHash = this.getInformationWarpPointHashForStarSystemID(context.starSystemId);

            starSystemInfo = this.informationStarSystemById[context.starSystemId];
            if (starSystemInfo) {
              swp1 = starSystemInfo.surveyWarpPass1;
              swp2 = starSystemInfo.surveyWarpPass2;
            }
          }
          // if destination is NOT known...
          else {
            context.starSystemId = "UNKNOWN";
            context.locationHex = "UNKNOWN";
          }
          context.atWP = true;

          context.canDoRough = swp1 <= 200;
          context.canDoDetail = swp2 <= 200 && !context.canDoRough;

          break;
        // probe orders return to original location, so no change
        case 171:
          order.estimate = (30 / order.speed) * 24;
          context.atWP = true;
          break;
        case 161:
          if (order.stage < 3) {
            let stmpUsed = (order.option === 'Both') ? 3 : 2;
            order.estimate = stmpUsed * (30 / order.speed) * 24;
          }
          else try {
            let historyIndex = order.hex.indexOf('|');
            if (historyIndex === -1) { historyIndex = order.hex.length; }
            let destination = (order.hex).substring(0, historyIndex);
            let destinationHexes = destination.split(',');
            let hours = 0;
            let fromHex = context.locationHex;
            for (const destinationHex of destinationHexes) {
              hours += this.utilityService.timeToTravel(fromHex, destinationHex, order.speed);
              fromHex = destinationHex;
            }
            order.estimate = hours + 2;
          }
          catch (error) {
            order.estimate = null;
          }
          context.atWP = true;
          break;
        // split off ships probe orders return to original location, so no change
        case 181:
        // @ts-ignore
        case 191:
          order.estimate = 2;
        // same with split/merge
        case 401:
        // @ts-ignore
        case 411:
          order.estimate = 2;
        // same with wait orders
        case 501:
        case 511:
          break;
        // patrol and survey instructions change locationHex and
        //   cannot predict if final location is at a warpPoint
        // @ts-ignore
        case 301:
          pointsPerStmp = fleet.XOnly + fleet.XXr;
          context.canDoRough = false; // assumes first 301 will be Rough
          context.canDoDetail = (order.option === 'Rough');
          pointsEarned = order.option === 'Rough' ? starSystemInfo.surveyWarpPass1 : starSystemInfo.surveyWarpPass2;
          pointsNeeded = 200;
        // @ts-ignore
        case 302:
          if (order.code === 302) {
            pointsPerStmp = fleet.XOnly;
            pointsEarned = order.option === 'Primary' ? starSystemInfo.surveyPlanetHabitable : starSystemInfo.surveyPlanetHabitable2;
          }
        // @ts-ignore
        case 303:
          if (order.code === 303) {
            pointsPerStmp = fleet.XOnly;
            pointsEarned = order.option === 'Primary' ? starSystemInfo.surveyPlanetNonHabitable : starSystemInfo.surveyPlanetNonHabitable2;
          }
        // @ts-ignore
        case 304:
          if (order.code === 304) {
            pointsPerStmp = fleet.XOnly;
            pointsEarned = order.option === 'Primary' ? starSystemInfo.surveyAsteroid : starSystemInfo.surveyAsteroid2;
          }
          let travelTime = (order.stage === 2) ? 0 : this.utilityService.timeToTravel(context.locationHex, '4232', order.speed) + 2;
          let pointsLeft = pointsNeeded - pointsEarned;
          let pointsPerPulse = pointsPerStmp / (30 / order.speed * 24 / 2);
          order.estimate = Math.ceil(travelTime + pointsLeft / pointsPerPulse * 2);
        case 131:
        default:
          context.locationHex = "UNKNOWN";
          context.atWP = false;
          break;
      };
    }
    return context;
  };

  // newDisabled (fleetId: string): boolean {
  //   let disabled = false;
  //   let fleetInstructions = this.fleetOrdersByFleetId[fleetId];
  //   if (fleetInstructions && fleetInstructions.length) {
  //     let fleetOrder = fleetInstructions[fleetInstructions.length - 1];
  //     disabled = (fleetOrder.code === 131 && fleetOrder.complete === 0);
  //   }
  //   return disabled;
  // };

  // lastOrderActive (fleetId: string): boolean {
  //   let active = false;
  //   let fleetInstructions = this.fleetOrdersByFleetId[fleetId];
  //   if (fleetInstructions && fleetInstructions.length) {
  //     let fleetOrder = fleetInstructions[fleetInstructions.length - 1];
  //     active = (fleetOrder.complete === 0);
  //   }
  //   return active;
  // };

  updateChoicesWithContext (fleet: Fleet, context: any) {
    let starSystemInfo: InformationStarSystem;
    let starSystem = this.starSystemById[context.starSystemId];
    let planetBreakdown = {
      h1: false,
      h2: false,
      n1: false,
      n2: false,
      a1: false,
      a2: false
    };
    if (starSystem) {
      starSystemInfo = this.informationStarSystemById[context.starSystemId];
      if (starSystem.stars[0]) {
        let star = starSystem.stars[0];
        let moons = [];
        for (const planet of star.planets) {
          planetBreakdown.h1 = planetBreakdown.h1 || (planet.hi >= 1 && planet.hi <= 10);
          planetBreakdown.n1 = planetBreakdown.n1 || (planet.hi >= 11 && planet.type != 'A');
          planetBreakdown.a1 = planetBreakdown.a1 || (planet.hi >= 11 && planet.type === 'A');
          if (!planetBreakdown.h1 || !planetBreakdown.n1) {
            moons.push(...planet.moons);
          }
        }
        if (!planetBreakdown.h1 || !planetBreakdown.n1) {
          for (const moon of moons) {
            planetBreakdown.h1 = planetBreakdown.h1 || (moon.hi >= 1 && moon.hi <= 10);
            planetBreakdown.n1 = planetBreakdown.n1 || moon.hi >= 11;
          }
        }
      }
      if (starSystem.stars[1]) {
        let star = starSystem.stars[1];
        let moons = [];
        for (const planet of star.planets) {
          planetBreakdown.h2 = planetBreakdown.h2 || (planet.hi >= 1 && planet.hi <= 10);
          planetBreakdown.n2 = planetBreakdown.n2 || (planet.hi >= 11 && planet.type != 'A');
          planetBreakdown.a2 = planetBreakdown.a2 || (planet.hi >= 11 && planet.type === 'A');
          if (!planetBreakdown.h2 || !planetBreakdown.n2) {
            moons.push(...planet.moons);
          }
        }
        if (!planetBreakdown.h2 || !planetBreakdown.n2) {
          for (const moon of moons) {
            planetBreakdown.h2 = planetBreakdown.h2 || (moon.hi >= 1 && moon.hi <= 10);
            planetBreakdown.n2 = planetBreakdown.n2 || moon.hi >= 11;
          }
        }
      }
    }

    // this.contextualShips = fleet.ships;
    for (const instruction of this.fleetInstructions()) {
      if (instruction.good == null) {
        instruction.good = true;
      }

      this.contextualFleets = this.fleetsByStarSystemId[fleet.starSystemId] || [];

      switch (instruction.code) {
        case 102:
          instruction.good = !(context.starSystemId === "UNKNOWN");
          break;
        case 103:
          instruction.good = false;
          // do you know of any warp points? need to check (# of infoWP)
          if (context.starSystemId != "UNKNOWN") {
            let informationWarpPoints = this.informationWarpPointsByStarSystemId[context.starSystemId] || [];
            instruction.good = (informationWarpPoints.length > 0);
          }
          break;
        case 104:
          instruction.good = !(context.starSystemId === "UNKNOWN");;
          break;
        case 111:
          instruction.good = (this.contextualFleets.length > 0);
          break;
        case 161:
          instruction.optionGood = {
            '1610': true,
            '1611': true,
            '1612': true
          };
          instruction.good = (context.atWP && context.hasXXr);
          break;
        case 171:
          instruction.good = context.atWP;
          break;
        case 181:
          instruction.optionGood = {
            '1810': true,
            '1811': true,
            '1812': true
          };
          instruction.good = false;
          // do you know of any warp points? need to check (# of infoWP)
          if (context.starSystemId != "UNKNOWN") {
            let informationWarpPoints = this.informationWarpPointsByStarSystemId[context.starSystemId] || [];
            instruction.good = (informationWarpPoints.length > 0);
          }
          break;
        case 191:
          instruction.good = false;
          // do you know of any warp points? need to check (# of infoWP)
          if (context.starSystemId != "UNKNOWN") {
            let informationWarpPoints = this.informationWarpPointsByStarSystemId[context.starSystemId] || [];
            instruction.good = (informationWarpPoints.length > 0);
          }
          break;
        case 201:
          instruction.good = context.atWP;
          break;
        case 101:
        case 131:
          break;
        case 301:
          instruction.optionGood = {
            '3010': context.canDoRough,
            '3011': context.canDoDetail
          };
          instruction.good = (instruction.optionGood['3010'] || instruction.optionGood['3011']);
          break;
        case 302:
          instruction.optionGood = {
            '3020': (starSystemInfo.surveyPlanetHabitable <= 50 && planetBreakdown.h1),
            '3021': (starSystemInfo.surveyPlanetHabitable2 <= 50 && planetBreakdown.h2)
          };
          instruction.good = (instruction.optionGood['3020'] || instruction.optionGood['3021']);
          break;
        case 303:
          instruction.optionGood = {
            '3030': (starSystemInfo.surveyPlanetNonHabitable <= 50 && planetBreakdown.n1),
            '3031': (starSystemInfo.surveyPlanetNonHabitable2 <= 50 && planetBreakdown.n2)
          };
          instruction.good = (instruction.optionGood['3030'] || instruction.optionGood['3031']);
          break;
        case 304:
          instruction.optionGood = {
            '3040': (starSystemInfo.surveyAsteroid <= 50 && planetBreakdown.a1),
            '3041': (starSystemInfo.surveyAsteroid2 <= 50 && planetBreakdown.a2)
          };
          instruction.good = (instruction.optionGood['3040'] || instruction.optionGood['3041']);
          break;
        case 411:
          instruction.good = (this.contextualFleets.length > 0);
          break;
        case 501:
          instruction.good = (this.contextualFleets.length > 0);
          break;
        case 511:
          instruction.optionGood = {
            '5110': true,
            '5111': true
          };
          break;
      }
    }
  };

  processFleetOrder (fleetOrder: FleetOrder) {
    let fleetOrders = this.fleetOrdersByFleetId()[fleetOrder.fleetId];
    let index = fleetOrders.findIndex(order => order._id === fleetOrder._id);
    if (index > -1) {
      fleetOrders[index] = fleetOrder;
    }
    else {
      fleetOrders.push(fleetOrder);
    }
  };

  editFleetOrder (fleetOrder: FleetOrder) {
    const dialogRef = this.dialog.open<DialogData>(FleetOrderEditComponent, {
      width: '65%',
      // height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "Fleet Order for " + this.fleetsById[fleetOrder.fleetId].fleetName,
        document: fleetOrder,
        instructions: this.fleetInstructions(),
        starSystemById: this.starSystemById,
        informationWarpPoints: (this.informationWarpPointsByStarSystemId[fleetOrder.endStarSystemId] || []),
        fleets: this.contextualFleets
      }
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let fleetOrder = result.document as FleetOrder;
        let fleetOrder$: Observable<FleetOrder>;
        if (fleetOrder['_id'] == null) {
          fleetOrder$ = this.fleetOrderService.saveNewFleetOrder(fleetOrder);
        } else {
          fleetOrder$ = this.fleetOrderService.saveFleetOrder(fleetOrder);
        }

        fleetOrder$.subscribe(
          fleetOrder => {
            this.processFleetOrder(fleetOrder);
            this.session.iAmNotBusy();
          }
        );
      }
    });
  };

  add (fleet: Fleet) {
    // if (!this.fleetOrdersByFleetId()[fleet._id]) {
    //   this.fleetOrdersByFleetId[fleet._id] = [];
    // }
    let fleetInstructions = this.fleetOrdersByFleetId()[fleet._id];

    let context = this.buildContext(fleet, fleetInstructions);

    this.updateChoicesWithContext(fleet, context);

    let fleetOrder: FleetOrder = {
      campaignId: this.session.getCampaign()._id,
      raceId: this.race._id,
      fleetId: fleet._id,
      code: 101,
      speed: fleet.speed,
      sequence: Date.now(),
      positionOriginal: fleet.locationHex,
      positionCurrent: fleet.locationHex,
      endStarSystemId: context.starSystemId,
      complete: 0,
      stage: 0,
      elapsed: 0
    };

    this.editFleetOrder(fleetOrder);
  };

  editInstruction (fleetOrder: FleetOrder) {
    this.editFleetOrder(fleetOrder);
  };

  delete (fleetId: string) {
    console.log("delete", fleetId);
    let fleetInstructions = this.fleetOrdersByFleetId()[fleetId];
    let lastIndex = (fleetInstructions?.length || 0) - 1;
    if (lastIndex > -1) {
      let fleetOrder = fleetInstructions[lastIndex];
      if (fleetOrder && fleetOrder.complete === 0) {
        this.fleetOrderService.deleteFleetOrder(fleetOrder).subscribe(
          () => setTimeout(() => fleetInstructions.pop())
        );
      }
    }
  };

  interrupt (fleetId: string) {
    let fleetInstructions = this.fleetOrdersByFleetId()[fleetId];
    if (fleetInstructions) {
      let fleetOrder = fleetInstructions.find(order => order.complete === 0);
      if (fleetOrder) {
        fleetOrder.complete = 2;
        this.fleetOrderService.saveFleetOrder(fleetOrder).subscribe();
      }
    }
  };
}

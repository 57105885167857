import { Component, WritableSignal, signal } from '@angular/core';

import { SessionService } from '../../services/session.service';
import { ShipClassService } from '../../services/ship-class.service';
import { SsdParserService } from '../../services/ssd-parser.service';

import { ShipClass } from '../../interfaces/ship-class';
import { map, tap } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { Dialog } from '@angular/cdk/dialog';
import { DialogData } from '../../interfaces/dialog-data';
import { ShipClassEditComponent } from '../ship-class-edit/ship-class-edit.component';
import { OutfitEditComponent } from '../outfit-edit/outfit-edit.component';

interface Funds {
  missileFund: number;
  fighterFund: number;
  smallCraftCost: number;
};

@Component({
  selector: 'ship-classes',
  templateUrl: './ship-classes.component.html',
})
export class ShipClassesComponent {
  shipClasses: WritableSignal<ShipClass[]> = signal([]);
  projects: any[];
  smallCraftProjects: any[];
  fighterProjects: any[];
  xoProjects: any[];
  projectHash: {};

  constructor (
    public session: SessionService,
    private projectService: ProjectService,
    private shipClassService: ShipClassService,
    private ssdParserService: SsdParserService,
    private dialog: Dialog,
  ) {

    var race = this.session.getRace();
    this.shipClassService.getShipClassesForRaceWithCount$(race).pipe(
      map(shipClasses => this.shipClasses = signal<ShipClass[]>(shipClasses))
    ).subscribe();

    this.projectService.getOutfitProjectsForRace(race._id).pipe(
      tap(outfitProjects => {
        this.projects = outfitProjects['standard'];
        this.smallCraftProjects = outfitProjects['smallCraft'];
        this.fighterProjects = outfitProjects['fighter'];
        this.xoProjects = [];
        this.projectHash = {};
        for (const project of this.projects) {
          this.projectHash[project['symbol']] = project;
          if (project['xoMountPoints'] > 0 && project['notations'].indexOf('tFO') == -1) {
            this.xoProjects.push(project);
          }
        }
        for (const project of this.smallCraftProjects) {
          this.projectHash[project['symbol']] = project;
        }
        for (const project of this.fighterProjects) {
          this.projectHash[project['symbol']] = project;
        }
      })
    ).subscribe();
  };

  processSavedShipClass (savedShipClass: ShipClass) {
    savedShipClass = this.updateItemCounts(savedShipClass);
    this.shipClassService.saveShipClass$(savedShipClass).pipe(
      tap((savedShipClass) => {
        // New shipClass will be added to the array
        // Edited shipClass will be updated in the array
        let found = false;
        this.shipClasses.update(shipClasses => {
          let newShipClasses: ShipClass[] = shipClasses.map(shipClass => {
            if (shipClass._id === savedShipClass._id) {
              found = true;
              return savedShipClass as ShipClass;
            }
            return shipClass;
          });
          if (!found) {
            newShipClasses.push(savedShipClass);
          }
          return newShipClasses;
        });
        return savedShipClass;
      })
    ).subscribe(
      () => {
        this.session.setNotifyMessage('Ship Class Saved');
        this.session.iAmNotBusy();
      }
    );
  };

  editShipClass (shipClass: ShipClass) {
    let shipClassToEdit = Object.assign({}, shipClass);
    const dialogRef = this.dialog.open<DialogData>(ShipClassEditComponent, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "ShipClass",
        document: shipClassToEdit,
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        this.processSavedShipClass(result.document as ShipClass);
      }
    });
  };

  newShipClass () {
    let shipClassToEdit = this.shipClassService.newShipClass(this.session.campaign._id, this.session.race._id);
    this.editShipClass(shipClassToEdit);
  };

  deleteShipClass (shipClassToDelete: ShipClass): void {
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        // title: "Warning",
        // message: "This cannot be undone!",
        documentName: "ShipClass",
        document: shipClassToDelete
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let shipClassId = result['document']['_id'];
        this.shipClassService.deleteShipClassForId$(shipClassId).pipe(
          tap(() => {
            this.shipClasses.update(shipClasses => shipClasses.filter(
              shipClass => (shipClass._id !== shipClassId)
            ));
          })
        ).subscribe(
          () => {
            this.session.setNotifyMessage('Ship Class Deleted');
            this.session.iAmNotBusy();
          }
        );
      }
    });
  };

  updateItemCounts (shipClass: ShipClass) {
    let items = this.ssdParserService.parseSsd(shipClass.ssd);
    let xTotal = 0;
    let syTotal = 0;
    let vTotal = 0;
    let mgTotal = 0;
    let boatBayPoints = 0;
    for (let j = 0; j < items.length; j++) {
      let item = items[j];
      if ('X' === item) { xTotal++; }
      else if ('SY' === item) { syTotal++; }
      else if ('V' === item) { vTotal++; }
      else if ('Mg' === item) { mgTotal++; }
      else if ('B' === item[0] && 'b' === item[1]) {
        if ('S' === item[2]) {
          boatBayPoints += 2;
        }
        else if ('M' === item[2]) {
          boatBayPoints += 4;
        }
        else if ('L' === item[2]) {
          boatBayPoints += 6;
        }
      }
    }
    shipClass.xCount = xTotal;
    shipClass.syCount = syTotal;
    shipClass.vCount = vTotal;
    shipClass.mgCount = mgTotal;
    shipClass.boatBayPoints = boatBayPoints;

    return shipClass;
  };

  private evaluateFunds (outfit: {}, funds: Funds): Funds {
    let keys = Object.keys(outfit);
    for (const key of keys) {
      let project = this.projectHash[key];
      let count: number = outfit[key] as number;
      if (count <= 0) {
        delete outfit[key];
      }
      else if (project.notations.indexOf('tM') > -1) {
        funds['missileFund'] += count * project.cost;
      }
      else if (project.notations.indexOf('tSC') > -1) {
        funds['smallCraftCost'] += count * project.cost;
      }
      else if (project.notations.indexOf('tF') > -1) {
        funds['fighterFund'] += count * project.cost;
      }
    }
    return funds;
  };

  openOutfitEditor (shipClass: ShipClass, outfitToEdit: {}, xoToEdit: {} = {}, fighterConfig: boolean = false) {
    const dialogRef = this.dialog.open<DialogData>(OutfitEditComponent, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: fighterConfig ? "Fighter Outfit" : "Outfit",
        document: shipClass,
        outfitToEdit: outfitToEdit,
        xoToEdit: xoToEdit,
        fighterMode: fighterConfig,
        projects: this.projects,
        smallCraft: this.smallCraftProjects,
        xoProjects: this.xoProjects,
        fighterProjects: this.fighterProjects,
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let funds = {
          'missileFund': 0,
          'fighterFund': 0,
          'smallCraftCost': 0
        };

        let shipClass: ShipClass = result['document'] as ShipClass;
        let outfitToEdit: {} = result['outfitToEdit'] as {};
        let xoToEdit: {} = result['xoToEdit'] as {};

        funds = this.evaluateFunds(outfitToEdit, funds);
        funds = this.evaluateFunds(xoToEdit, funds);
        shipClass.missileFund = funds.missileFund;
        shipClass.fighterFund = funds.fighterFund;
        shipClass.smallCraftCost = funds.smallCraftCost;

        if (fighterConfig) {
          shipClass.fighterOutfit = JSON.stringify(outfitToEdit);
        }
        else {
          outfitToEdit['XO'] = xoToEdit;
          shipClass.outfit = JSON.stringify(outfitToEdit);
        }

        this.processSavedShipClass(shipClass);
      }
    });
  };

  editShipClassOutfit (shipClass: ShipClass) {
    let xoToEdit = {};
    let outfitToEdit = {};
    let selections = JSON.parse(shipClass.outfit || '{}');
    for (const symbol in selections) {
      if (Object.hasOwnProperty.call(selections, symbol)) {
        const count = selections[symbol];
        if (symbol === 'XO') {
          // count is actually a json substructure, not a count
          xoToEdit = count;
        }
        else {
          outfitToEdit[symbol] = count;
        }
      }
    }
    this.openOutfitEditor(shipClass, outfitToEdit, xoToEdit);
  };

  editShipClassFighterOutfit (shipClass: ShipClass) {
    let outfitToEdit = {};
    let selections = JSON.parse(shipClass.fighterOutfit || '{}');
    for (const symbol in selections) {
      if (Object.hasOwnProperty.call(selections, symbol)) {
        const count = selections[symbol];
        outfitToEdit[symbol] = count;
      }
    }
    this.openOutfitEditor(shipClass, outfitToEdit, null, true);
  };
};

import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { SessionService } from '../../services/session.service';
import { ShipClassService } from '../../services/ship-class.service';

import { Race } from '../../interfaces/race';
import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'shared-ship-classes',
  templateUrl: './shared-ship-classes.component.html',
})
export class SharedShipClassesComponent {
  race: Race;
  shipClasses: Signal<ShipClass[]>;

  constructor(
    public session: SessionService,
    private shipClassService: ShipClassService,
  ) {
    this.race = this.session.getRace();
    this.shipClasses = toSignal<ShipClass[]>(this.shipClassService.getSharedShipClasses$(this.race));
  };

  copyShipClass ( shipClass: ShipClass ) {
    this.session.iAmBusy();
    let newShipClass = this.shipClassService.copyShipClass(shipClass, this.race);
    this.shipClassService.saveNewShipClass$(newShipClass).subscribe(
      () => {
        this.session.setNotifyMessage('Ship Class Copied');
        this.session.iAmNotBusy();
      }
    );
  };
}
